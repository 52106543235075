<template>
    <svg fill="none" height="28" viewBox="0 0 703 177" width="111.21" xmlns="http://www.w3.org/2000/svg">
        <path d="M149.42 0V36.036H95.4916V176.4H54.1636V36.036H0.487549V0H149.42Z" fill="black"/>
        <path d="M139.609 0H180.937V176.4H139.609V0Z" fill="black"/>
        <path
            d="M282.338 86.436L343.07 176.4H292.922L272.51 145.656L255.122 117.18L238.238 145.404L217.826 176.4H167.174L226.898 88.2L170.45 0H219.086L237.734 29.736L254.366 58.464L271.754 29.736L290.906 0H339.794L282.338 86.436Z"
            fill="black"/>
        <path
            d="M401.366 0C419.51 0 435.386 3.612 448.994 10.836C462.77 18.06 473.354 28.392 480.746 41.832C488.306 55.104 492.086 70.56 492.086 88.2C492.086 105.84 488.306 121.38 480.746 134.82C473.354 148.092 462.77 158.34 448.994 165.564C435.386 172.788 419.51 176.4 401.366 176.4H329.042V0H401.366ZM401.366 140.364C416.15 140.364 428.078 135.66 437.15 126.252C446.222 116.844 450.758 104.16 450.758 88.2C450.758 77.616 448.658 68.46 444.458 60.732C440.426 52.836 434.63 46.788 427.07 42.588C419.678 38.22 411.11 36.036 401.366 36.036H370.37V140.364H401.366Z"
            fill="black"/>
        <path
            d="M617.677 176.4L604.573 144.648H517.633L504.529 176.4H460.429L539.557 0H583.657L662.281 176.4H617.677ZM561.481 39.564L532.501 109.116H589.957L561.481 39.564Z"
            fill="black"/>
        <circle cx="684" cy="157.4" r="19" fill="#4BE08D"/>
    </svg>
</template>
